@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

:root {
  --primary-white: white;
  --primary-grey: #a9a9a9;
  --primary-black: black;
  --primary-lightg: #f4f4f4;
}

body {
  font-family: "Roboto Condensed" sans-serif !important;
}


/** NAVBAR **/
nav a.nav-link {
  cursor: pointer;
}

nav ul li {
  font-weight: bold;
  text-transform: uppercase;
}

.navbar-nav > li > a {
  color: var(--primary-white) !important;
  margin-top: 15px;
  margin-left: 10px;
}

.navbar-nav > li > a:hover {
  color: var(--primary-grey) !important;
}

.navbar-toggler {
  margin-top: 15px;
}

@media(max-width: 468px) {
  .navbar-toggler {
    margin-left: 0.8rem;
  }
}

@media(max-width: 1250px) {
  .navbar-toggler {
    margin-left: .5rem;
  }
}

/** LANDING **/
.frontImage {
  background-image: url("./images/landing.jpg");
  height: 100vh;
  background-position: center;
  background-size: cover;
}

.containerLanding {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
}

/** ABOUT **/
.photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-img {
  width: 20rem;
  border-radius: 10px;
  padding: .3rem;
}

@media(max-width: 468px) {
  .profile-img {
    width: 20rem;
    margin-bottom: 25px;
  }
}

@media(max-width: 280px) {
  .profile-img {
    width: 16rem;
    margin-bottom: 25px;
  }
}

.pics-img {
  width: 12rem;
  padding: .3rem;
  border-top: 1px solid var(--primary-grey);
}

@media(max-width: 468px) {
  .pics-img {
    display: none;
  }
}

@media(max-width: 1250px) {
  .pics-img {
    width: 10rem;
  }
}

/** EXPERIENCE **/
.click {
  min-width: 100px;
  width: 100%;
  background-color: Transparent;
  background-repeat: no-repeat;
  text-align: left !important;
  border-color: gray;
  border-left: 2px solid !important;
  border-bottom: none !important;
  border-top: none !important;
  border-right: none !important;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  color: inherit !important;
  border-radius: 0 !important;
}

.click:focus,
.click:active {
  outline: none;
  box-shadow: none !important;
}

.click:hover {
  border-color: #b08d57 !important;
  background-color: #0f0f0f !important;
  color: #b08d57 !important;
}

.selected {
  border-color: #b08d57 !important;
  color: #b08d57 !important;
}

.header {
  display: flex;
  align-items: center;
}

.header::after {
  margin-top: 5px;
  content: "";
  flex: 1;
  margin-left: 1rem;
  height: 1px;
  background-color: #000;
}

.min-height {
  min-height: 320px !important;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 767px) {
  .click {
    border-color: gray;
    height: 100%;
    border-bottom: 2px solid !important;
    border-left: none !important;
  }
  .buttonContainer {
    display: flex;
    overflow-x: auto;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .buttonContainer::-webkit-scrollbar {
    display: none;
  }
  .header {
    justify-content: center !important;
  }
  .header::after {
    margin-top: 0px;
    content: "";
    flex: 0;
    margin-left: 0;
    height: 0;
    background-color: #000;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .test {
    width: 800px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .test {
    width: 1000px !important;
  }
}

/** PROJECTS **/
.projects {
  text-align: start;
  padding-bottom: 2rem;
}

.projects h1 {
  text-align: center;
}

.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 400px;
}

.card-img-top {
  width: 100%; /* Set a fixed width for the image */
  height: 235px; /* Set a fixed height for the image */
  object-fit: cover; /* Ensure the image covers the entire space, even if aspect ratio is not maintained */
}

.card-body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.custom-button {
  width: 125px;
}

/** FOOTER **/
.footer {
  padding: .5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer p {
  text-align: center;
  margin-bottom: 0;
}

.footer nav a.nav-link {
  cursor: pointer;
}

.footer .navbar-nav > li > a {
  color: var(--primary-black) !important;
}